export class Log {
    id: string;
    fecha: string;
    status: string;
    mensaje: string;
    transportista?: string;
    nroRuta?: string;
    nroPedido?: string;
    descripcion: string;
}
