import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MensajesService } from './mensajes.service';
import { Log } from '../models/log';
import { LogsService } from './logs.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    public afAuth: AngularFireAuth,
    private msjs: MensajesService,
    private logsService: LogsService
  ) { }


  async loginConCorreo(email: string, password: string) {
    try {
      return await this.afAuth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          this.msjs.mensajeError(
            'Usuario no encontrado',
            'El usuario ingresado no se encuentra registrado.'
          );
          break;
        case 'auth/wrong-password':
          this.msjs.mensajeError(
            'Error de contraseña',
            'La contraseña ingresada es incorrecta.'
          );
          break;
        default:
          this.msjs.mensajeError(
            'Ha ocurrido un error',
            'Por favor notifica al Administador.'
          );
          break;
      }

      const log: Log = new Log();
      log.id = this.logsService.afs.createId();
      log.fecha = new Date().toLocaleString('es-CL');
      log.status = error.code;
      log.mensaje = error.message;

      await this.logsService.agregarLog(log).then();
    }
  }

  async registrarConCorreo(email: string, password: string) {
    try {
      return await this.afAuth.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          this.msjs.mensajeError(
            'Email existente',
            'El email ingresado se encuentra registrado.'
          );
          break;

        default:
          this.msjs.mensajeError(
            'Ha ocurrido un error',
            'Por favor notifica al Administador.'
          );
          break;
      }

      const log: Log = new Log();
      log.id = this.logsService.afs.createId();
      log.fecha = new Date().toLocaleString('es-CL');
      log.status = error.code;
      log.mensaje = error.message;

      await this.logsService.agregarLog(log).then();

    }
  }

  async recuperarPassword(email: string) {
    return await this.afAuth.sendPasswordResetEmail(email);
  }

  async logout() {
    try {
      return await this.afAuth.signOut();
    } catch (error) {
      switch (error.code) {
        default:
          this.msjs.mensajeError(
            'Ha ocurrido un error',
            'Por favor notifica al Administador.'
          );
          break;
      }

      const log: Log = new Log();
      log.id = this.logsService.afs.createId();
      log.fecha = new Date().toLocaleString('es-CL');
      log.status = error.code;
      log.mensaje = error.message;

      await this.logsService.agregarLog(log).then();
    }
  }

}
