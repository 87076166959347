import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Log } from '../models/log';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private url = 'logs';
  constructor(public afs: AngularFirestore) { }

  agregarLog(log: Log) {
    const ref = this.afs.collection<Log>(this.url);
    return ref.doc<Log>(log.id).set(Object.assign({}, log));
  }

  actualizarLog(log: Log) {
    const ref = this.afs.collection<Log>(this.url);
    return ref.doc(log.id).update(Object.assign({}, log));
  }

  obtenerLog(id: string) {
    const ref = this.afs.collection<Log>(this.url);
    return ref.doc(id).valueChanges();
  }


  listarLogs(tipoRegistro: string) {
    return this.afs.collection<Log>(
      this.url, ref => ref.where('tipoRegistro', '==', tipoRegistro)).valueChanges();
  }

}
